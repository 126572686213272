<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="mb-0 pb-0">OKNOTOK!</h2>
          <h5 class="mt-0 mb-3">3:30@D 2024</h5>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="btn-group-vertical">
            <button v-for="(button, index) in galleryButtons" :key="index" class="gallery_button btn btn-sm btn-outline-dark text-start" @click="handleButtonClick(button.id)">{{button.label}}</button>
            <a href="/galleries" class="text-start gallery_button btn btn-sm mt-3 btn-outline-dark">All Galleries</a>
            <a href="/users/sign_in" class="text-start gallery_button btn btn-sm mt-3 btn-outline-dark">Put in Log</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import * as PhotoSphereViewer from "@photo-sphere-viewer/core";
export default {
  name: 'Frontpage',
  data() {

    const selectedGalleryItems = [];

    const lightbox = new PhotoSwipeLightbox({
      preload: [1, 2],
      pswpModule: () => import('photoswipe')
    });

    lightbox.addFilter('numItems', (numItems) => {
      return selectedGalleryItems.length;
    });

    lightbox.addFilter('itemData', (itemData, index) => {
      let gi = selectedGalleryItems[index];
      return {
        src: gi.full_url,
        width: gi.width,
        height: gi.height,
        item_type: gi.type,
        is_vr180: gi.is_vr180,
      };
    });

    lightbox.on('contentLoad', (e)=>{
      const { content, isLazy } = e;
      if(content.data.item_type != 'Photo' || content.data.is_vr180){
        e.preventDefault();
        content.element = document.createElement('div');
        content.element.className = 'pswp__sphereContainer';
        const iframe = document.createElement('iframe');
        iframe.setAttribute('allowfullscreen', '');
        iframe.setAttribute('scrolling', 'no');
        iframe.src = "vr?image=" + content.data.src + "&is_stereo=" + false;
        content.element.appendChild(iframe);
      }else{
          console.log(content.data.item_type)
        }

    });

    lightbox.seletedGalleryItems = [];

    lightbox.init();

    return {
      galleryButtons: gon.galleries, // Add your gallery buttons data here
      selectedGalleryItems,
      lightbox
    };
  },
  mounted() {

  },
  methods: {
    handleButtonClick(galleryId) {
      fetch(`/galleries/${galleryId}.json?disable_vr180=true`)
          .then(response => response.json())
          .then(serverData => {
            const gallery = serverData.galleries[0];
            const gallery_items = gallery.items;

            this.selectedGalleryItems.length = 0
            this.selectedGalleryItems.push(...gallery_items);
            //console.log(this.lightbox.seletedGalleryItems)

            this.lightbox.loadAndOpen(0);
          });
    }
  }
};

</script>

<style scoped>
.pswp__sphereContainer {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
